@import '_variables.scss';


.form__response {
    color: #78bd49;
    font-weight: 600;
    margin-bottom: 50px;
    margin-top: 50px;
}

.form__title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 15px;
}

.form__sub-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 27px;
}

.form__message {
    background-color: #eaf5ff;
    padding: 14px 16px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    margin-bottom: 27px;
}

.form__form {
    margin-bottom: 60px;
}

.form__group {
    margin-bottom: 30px;
}

input[type="email"] {
    margin-bottom: 10px;
}

.form__label {
    margin-bottom: 12px;
}

.form__password-wrapper {
    position: relative;
}

input[type="password"] {
    margin-bottom: 10px;
}

.form__forgot-password-wrapper {
    text-align: right;
}

.form__forgot-password {
    font-weight: 500;
}

.form__actions {
    display: flex;

    @media(max-width:$max-width--phone) {
        flex-direction: column;
    }
}

.form__error {
    color: #f74444;
    font-weight: 600;
}

.errorlist {
    padding-left: 20px;
}

.errorlist.nonfield {
    padding-left: 0px;

    li::marker {
        color: #ffffff;
    }
}


.form-error {
    background-color: white;
    color: #f74444;
    animation-name: unset;
    animation-duration: unset;
    animation-fill-mode: unset;
    text-align: left;
    padding-left: 0px;
}

.form__button {
    height: 40px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    // padding: 12px 25px 13px;
    border-radius: 4px;
}

.form__button--login {
    background-color: #3B8BD8;
    color: #FFFFFF;
    border: 1px solid transparent;
    width: 175px;
    margin-right: 25px;
    transition: background-color 125ms ease-in-out;

    @media(max-width:$max-width--phone) {
        margin-bottom: 20px;
        width: 100%;
    }

    &:hover {
        background-color: #539ce0;
        transition: background-color 125ms ease-in-out;
    }

}

.form__button--email-login {
    border: 1px solid #3B8BD8;
    color: #3B8BD8;
    background-color: #FFFFFF;
    flex: 1;
    transition: background-color, color 125ms ease-in-out, color 125ms ease-in-out;

    @media(max-width:$max-width--phone) {
        flex: unset;
        width: 100%;
    }

    &:hover {
        background-color: #3B8BD8;
        color: #FFFFFF;
        transition: background-color 125ms ease-in-out, color 125ms ease-in-out;
    }
}

.form__button--disabled {
    cursor: not-allowed;
    background-color: #cccccc;
    color: #000000;
    pointer-events: none;
    opacity: 0.5;
    border-color: #cccccc;
}
