@import '_variables.scss';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 90px;
}

.header__logo-wrapper {
    flex: 1;
}

.header__logo {
    width: 245px;
}

.languageSwitcher__account {
    position: relative;

    &:before {
        font-family: 'font awesome 5 free';
        position: absolute;
        pointer-events: none;
        right: 10px;
        top: 7px;
        color: #222;
        z-index: 3;
        content: "\f107";
        font-size: 16px;
        font-weight: 900;
    }
}

.language-switcher__select {
    height: 36px;
    border-radius: 4px;
    color: black;
    background-color: white;
    border-color: #d3d3d3;
    padding: 3px 30px 0 10px;
    appearance: none;
    cursor: pointer;

    &:focus-visible {
        outline: none;
    }
}
