@import '_variables.scss';

.layout {
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
}

.layout__page {
    width: 745px;
    padding: 65px 120px;

    @media(max-width:$max-width--tablet) {
        width: 545px;
        padding: 25px;
    }

    @media(max-width:$max-width--phone) {
        width: 100%;
    }
}

.layout__background-overlay {
    width: 100%;
    min-height: 100%;

    @media(max-width:$max-width--phone) {
        background: none;
    }
}

.layout__background {
    flex: 1;
    width: 100%;
    min-height: 100vh;
    background-image: var(--image-url);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media(max-width:$max-width--phone) {
        background: none;
    }
}
