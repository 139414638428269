@import '_variables.scss';

.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.signup__wrapper {
    text-align: center;
    font-size: 16px;
}

.signup__title {
    font-weight: 600;
    font-size: 16px;
    padding: 10px 20px;
    background-color: #FFFFFF;
    z-index: 2;
}

.signup__rule {
    position: absolute;
    z-index: 1;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1;
    border-top: 1px solid #d3d3d3;
    width: 100%;
}

.signup__link {
    color: #4790d0;
    font-weight: 600;
    transition: color 125ms ease-in-out;
    font-size: 16px;

    &:hover {
        transition: color 125ms ease-in-out;
        color: #2d669c;
    }
}

.signup__wrapper {
    color: #0000009d;
    font-size: 14px;
}
